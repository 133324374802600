<template>
    <div class="box">
        <div class="box_con">
            <div class="box_title">
                <img src="@/assets/all/log1.png" alt="" />
                <p>劢楷</p>
            </div>
            <div class="box_text">用科技点亮健康生活！</div>
        </div>
        <div class="box_com">
            <div class="main">
                <div class="main_box">产品</div>
                <div
                    class="main_text"
                    v-for="(i, j) in list"
                    :key="j"
                    @click="toTop"
                >
                    <router-link class="main_text"
                        :to="{
                            path: '/list',
                            query: { id: i.id, pid: i.pid, state: j },
                        }"
                    >
                        {{ i.cate_name }}</router-link
                    >
                </div>
            </div>
            <div class="main" v-for="(item, index) in mainList" :key="index">
                <div class="main_box">{{ item.name }}</div>
                <div
                    class="main_text"
                    v-for="(i, j) in item.list"
                    :key="j"
                    @click="toTop"
                >
                    <router-link class="main_text" :to="i.to"> {{ i.cate_name }}</router-link>
                </div>
            </div>
            <div class="main">
                <div class="main_box">联系我们</div>
                <div class="main_cen_box">
                    <div class="main_cen_com">
                        <img src="../assets/all/erwei.jpg" alt="" />
                        <p>公众号</p>
                    </div>
                    <div class="main_cen_com">
                        <img src="../assets/all/erwei2.jpg" alt="" />
                        <p>劢楷商城</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="linst"></div>
        <div class="box_cent">
            <div class="box_left">
            Copyright  2024 劢楷科技(江苏)有限公司 版权所有 | 
            <a class="box_left" style="margin-left: 6px;" href="https://beian.mps.gov.cn/#/query/webSearch?code=32111102000436" rel="noreferrer" target="blank">苏ICP备2024061853号-1</a>
            </div>
            <div class="box_right" @click="toTop">
                <router-link :to="{ path: '/terms' }"
                    ><p class="box_titls">使用条款</p></router-link
                >
                <router-link :to="{ path: '/policy' }"
                    ><p class="box_titls">隐私政策</p></router-link
                >
            </div>
        </div>
        <div class="box_cents">
            <a class="box_left" href="https://beian.mps.gov.cn/#/query/webSearch?code=32111102000436" rel="noreferrer" target="blank"><img src="../assets/a.png" alt="">苏公网安备32111102000436号</a>
        </div>
    </div>
</template>

<script>
import { category } from "../api/api";
export default {
    name: "HelloWorld",
    props: {},
    data() {
        return {
            list: [],
            mainList: [
                {
                    name: "解决方案",
                    list: [
                        {
                            cate_name: "疤痕治疗",
                            to: {
                                path: "/solution",
                            },
                        },
                        {
                            cate_name: "压疮预防",
                            to: {
                                path: "/list",
                                query: {
                                    id: 46,
                                    pid: 0,
                                    state: 1,
                                },
                            },
                        },
                        {
                            cate_name: "疼痛管理",
                            to: {
                                path: "/ache",
                            },
                        },
                        {
                            cate_name: "膳食补充",
                            to: {
                                path: "/list",
                                query: {
                                    id: 47,
                                    pid: 0,
                                    state: 2,
                                },
                            },
                        },
                    ],
                },
                {
                    name: "医疗创新",
                    list: [
                        {
                            cate_name: "数字化养老平台",
                            to: {
                                path: "/",
                            },
                        },
                        {
                            cate_name: "专业化医疗服务",
                            to: {
                                path: "/",
                            },
                        },
                    ],
                },
            ],
        };
    },
    created() {
        this.categoryList();
    },
    methods: {
        categoryList() {
            this.list=[]
            category().then((res) => {
                for(let i=0;i<res.data.length;i++){
                    if(res.data[i].is_display == 1){
                        this.list.push(res.data[i])
                    }
                }
            });
        },
        toTop() {
            document.documentElement.scrollTop = 0;
        },
    },
};
</script>

<style scoped lang="less">
* {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
    color: #868686;
}
/*设置点击后的样式 */
.router-link-active {
    text-decoration: none;
    color: #868686;
}
.linst{
    width: 15.8rem;
    border-bottom: 0.01rem solid #eeeeee;
}
.box {
    width: 19.2rem;
    border-top: 0.01rem solid #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    background:#898989;

    .box_con {
        width: 15.8rem;
        height: 2rem;
        border-bottom: 0.01rem solid #eeeeee;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .box_title {
            display: flex;
            align-items: center;
            margin-bottom: 0.3rem;

            img {
                width: 2.2rem;
                height: 0.27rem;
                margin: 0.06rem 0.06rem 0 0;
            }
            p {
                font-size: 0.3rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #fff;
            }
        }
        .box_text {
            font-size: 0.26rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fff;
            display: flex;
        }
    }
    .box_com {
        width: 17.5rem;
        padding: 0.3rem 0 0.4rem 0;
        display: flex;
        justify-content: space-between;

        .main {
            margin: 0 0.8rem;
            display: flex;
            flex-direction: column;

            .main_box {
                font-size: 0.26rem;
                font-family: Source Han Sans CN;
                font-weight: 600;
                color: #fff;
            }
            .main_text {
                font-size: 0.2rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color:#fff;
                margin-top: 0.25rem;
                cursor: pointer;
            }
            .main_cen_box {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.32rem;

                .main_cen_com {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 0.6rem;

                    img {
                        width: 1.34rem;
                        height: 1.34rem;
                    }
                    p {
                        font-size: 0.2rem;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #fff;
                        margin-top: 0.2rem;
                    }
                }
            }
        }
    }
    .box_left_cem {
        width: 15.9rem;
        display: flex;
        align-items: center;
        margin-bottom: 0.4rem;
        font-size: 0.18rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #666;
        p {
            color: #ed155c;
        }
    }
    .box_cents{
        width: 15.9rem;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        
    }
    .box_left {
            font-size: 0.18rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #f3f1f1;
            display: flex;
        align-items: center;
            img{
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        }
    .box_cent {
        width: 15.9rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.2rem 0 0.2rem 0;

        
        .box_right {
            display: flex;
            font-size: 0.18rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            cursor: pointer;

            .box_titls{
                font-size: 0.18rem;
                margin-left: 0.4rem;
                color: #f3f1f1;
            }
        }
    }
}
</style>