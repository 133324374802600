<template>
    <div id="app">
        <!-- <HelloWorld :state="state" :orderPage="orderPage"></HelloWorld> -->
        <router-view></router-view>
        <FooterBox></FooterBox>
    </div>
</template>

<script>
// import HelloWorld from "@/components/HelloWorld.vue";
import FooterBox from "@/components/FooterBox.vue";
export default {
    name: "App",
    data() {
        return {
            state: 0,
            trends: 0,
            orderPage:0
        };
    },
    components: {
        // HelloWorld,
        FooterBox,
    },
    watch: {
        //监听$route的变化,更新data中的state
        // $route() {
        //     this.state = this.$route.query.index;
        //     this.trends = this.$route.query.trends;
        //     // this.orderPage = this.$route.query.orderPage
        //     if (this.state == undefined && this.trends == 1) {
        //         this.state = 2;
        //         return;
        //     }
        //     if (this.state == undefined && this.trends == 2) {
        //         this.state = 3;
        //         return;
        //     }
        //     console.log(this.orderPage,this.$route.query.orderPage);
        //     if(this.$route.query.orderPage==undefined){
        //         this.orderPage =  1
        //         return;
        //     }else{
        //         this.orderPage = this.$route.query.orderPage
        //     }
        // },
    },
};
</script>

<style scoped lang="less">
* {
    margin: 0;
    padding: 0;
}
</style>
