import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vant from 'vant';
import ElementUI from 'element-ui'; // 2.1引入结构
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
import 'vant/lib/index.css';
import store from './store'

Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
import VueVideoPlayer from 'vue-video-player'

// 引入样式
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'

// 使用组件
Vue.use(VueVideoPlayer)
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
window.onload = function () {
	getRem(1920, 100)
};
window.onresize = function () {
	getRem(1920, 100)
};

function getRem(pwidth, prem) {
	var html = document.getElementsByTagName("html")[0];
	var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
	html.style.fontSize = oWidth / pwidth * prem + "px";
}