import service from '../request/index'
//新闻列表
export function articleList(query) {
  return service({
    url: 'article/list/1',
    method: "get",
    params: query
  })
}
//新闻列表详情
export function articleDetails(id) {
  return service({
    url: 'article/details/'+id,
    method: "get"
  })
}
//分类
export function category(data) {
  return service({
    url: 'category',
    method: "get",
    data
  })
}

//列表
export function products(query) {
  return service({
    url: 'products',
    method: "get",
    params: query
  })
}

export function productDetail(id) {
  return service({
    url: 'product/detail/'+id,
    method: "get"
  })
}
//轮播图
export function groupData(data) {
  return service({
    url: '/pc/get_banner',
    method: "get",
    data
  })
}
