var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"box_com"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"main_box"},[_vm._v("产品")]),_vm._l((_vm.list),function(i,j){return _c('div',{key:j,staticClass:"main_text",on:{"click":_vm.toTop}},[_c('router-link',{staticClass:"main_text",attrs:{"to":{
                        path: '/list',
                        query: { id: i.id, pid: i.pid, state: j },
                    }}},[_vm._v(" "+_vm._s(i.cate_name))])],1)})],2),_vm._l((_vm.mainList),function(item,index){return _c('div',{key:index,staticClass:"main"},[_c('div',{staticClass:"main_box"},[_vm._v(_vm._s(item.name))]),_vm._l((item.list),function(i,j){return _c('div',{key:j,staticClass:"main_text",on:{"click":_vm.toTop}},[_c('router-link',{staticClass:"main_text",attrs:{"to":i.to}},[_vm._v(" "+_vm._s(i.cate_name))])],1)})],2)}),_vm._m(1)],2),_c('div',{staticClass:"linst"}),_c('div',{staticClass:"box_cent"},[_vm._m(2),_c('div',{staticClass:"box_right",on:{"click":_vm.toTop}},[_c('router-link',{attrs:{"to":{ path: '/terms' }}},[_c('p',{staticClass:"box_titls"},[_vm._v("使用条款")])]),_c('router-link',{attrs:{"to":{ path: '/policy' }}},[_c('p',{staticClass:"box_titls"},[_vm._v("隐私政策")])])],1)]),_vm._m(3)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_con"},[_c('div',{staticClass:"box_title"},[_c('img',{attrs:{"src":require("@/assets/all/log1.png"),"alt":""}}),_c('p',[_vm._v("劢楷")])]),_c('div',{staticClass:"box_text"},[_vm._v("用科技点亮健康生活！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_box"},[_vm._v("联系我们")]),_c('div',{staticClass:"main_cen_box"},[_c('div',{staticClass:"main_cen_com"},[_c('img',{attrs:{"src":require("../assets/all/erwei.jpg"),"alt":""}}),_c('p',[_vm._v("公众号")])]),_c('div',{staticClass:"main_cen_com"},[_c('img',{attrs:{"src":require("../assets/all/erwei2.jpg"),"alt":""}}),_c('p',[_vm._v("劢楷商城")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_left"},[_vm._v(" Copyright 2024 劢楷科技(江苏)有限公司 版权所有 | "),_c('a',{staticClass:"box_left",staticStyle:{"margin-left":"6px"},attrs:{"href":"https://beian.mps.gov.cn/#/query/webSearch?code=32111102000436","rel":"noreferrer","target":"blank"}},[_vm._v("苏ICP备2024061853号-1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_cents"},[_c('a',{staticClass:"box_left",attrs:{"href":"https://beian.mps.gov.cn/#/query/webSearch?code=32111102000436","rel":"noreferrer","target":"blank"}},[_c('img',{attrs:{"src":require("../assets/a.png"),"alt":""}}),_vm._v("苏公网安备32111102000436号")])])
}]

export { render, staticRenderFns }