import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode:'history',
    routes: [
        //首页
        {
            path: '/',
            component: () => import("@/pages/Home/HomeIndex.vue")
        },
        //公司介绍
        {
            path: '/firm',
            component: () => import("@/pages/Firm/FirmIndex.vue")
        },
        //产品列表
        {
            path: '/list',
            component: () => import("@/pages/List/ProductList.vue")
        },
        //产品详情
        {
            path: '/del',
            component: () => import("@/pages/List/ListDel.vue")
        },
        //产品详情
        {
            path: '/maxxcair',
            component: () => import("@/pages/List/MaxxCair.vue")
        },
        //联系信息
        {
            path: '/contact',
            component: () => import("@/pages/Contact/ContactBox.vue")
        },
        //新闻动态
        {
            path: '/trends',
            component: () => import("@/pages/Trends/NewTrends.vue")
        },
        //新闻动态详情
        {
            path: '/details',
            component: () => import("@/pages/Trends/TrendsDetails.vue")
        },
        //法律说明
        {
            path: '/law',
            component: () => import("@/pages/Law/LawIndex.vue")
        },
        //使用条款
        {
            path: '/terms',
            component: () => import("@/pages/Terms/TermsIndex.vue")
        },
        //隐私政策
        {
            path: '/policy',
            component: () => import("@/pages/Policy/PolicyIndex.vue")
        },
        //解决方案
        {
            path: '/solution',
            component: () => import("@/pages/Solution/SolutionBox.vue")
        },
        //疼痛管理
        {
            path: '/ache',
            component: () => import("@/pages/ache/acheIndex.vue")
        }
    ]
})



export default router;